import Splide from '@splidejs/splide';

let HeroSplide  = document.querySelectorAll('.hero-splide')

if (HeroSplide) {
	HeroSplide.forEach(function(el) {
		new Splide( el, {
			type   : 'fade',
			omitEnd : true,
			perMove : 1,
			arrows: false,
			autoplay : true,
			interval : "5000",
			rewind: true,
			i18n : {
				prev : "Slide precedente",
				next : "Slide successiva",
				first : "Vai alla prima pagina",
				last : "Vai all'ultima pagina",
				pageX: "Vai a pagina %s"
			},
		}).mount();
	})
}