//import css
import '@splidejs/splide/css/core';
import "./scss/main.scss";

//import components js
import "./components/backtotop/backtotop.js";
import "./components/banner/banner.js";
import "./components/button/button.js";
import "./components/editorial-content/editorial-content.js";
import "./components/footer/footer.js";
import "./components/header/header.js";
import "./components/hero/hero.js";
import "./components/img-bg/img-bg.js";
import "./components/page-title/page-title.js";
import "./components/skiplinks/skiplinks.js";
import "./components/splide-slide/splide-slide.js";
import "./components/splide/splide.js";
import "./components/text-center/text-center.js";

import "./js/browser-detect";
import "./js/lazyload";
